<!-- drawer component -->
<button class="hidden" #hideButton data-drawer-hide="side-drawer"></button>
<div
  id="side-drawer"
  class="fixed top-0 right-0 z-40 h-screen p-8 transition-transform translate-x-full text-gray10 w-[450px] bg-primary font-Satoshi"
  tabindex="-1"
  #drawerContainer
>
  <button
    type="button"
    data-drawer-hide="side-drawer"
    aria-controls="side-drawer"
    class="absolute top-10 -left-6"
    [ngClass]="{ hidden: !isOpen }"
  >
    <div
      class="bg-white text-black rounded-full w-12 h-12 flex items-center justify-center relative z-50"
    >
      <svg
        class="w-3 h-3"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </div>
    <span class="sr-only">Close menu</span>
  </button>
  <div class="flex w-full flex-col overflow-y-auto px-4">
    <div class="flex gap-4 items-center">
      <div
        class="bg-gray15 w-12 h-12 flex justify-center items-center rounded-full"
      >
        <img src="/assets/imgs/icons/heroicons-outline/user.svg" alt="" />
      </div>
      <div class="flex flex-col gap-1">
        <div class="font-bold text-lg">
          {{ authService.profile?.value?.first_name }}
          {{ authService.profile?.value?.last_name }}
        </div>
        <div class="text-sm font-normal">
          {{ authService.profile?.value?.email }}
        </div>
      </div>
    </div>
  </div>
  <ul
    class="border-t border-b border-blue2 px-4 mt-10 pt-6 text-base font-semibold"
  >
    <li>
      <a
        routerLink="account/profile"
        class="mb-6 flex items-center gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1241 5C13.1241 6.72589 11.7249 8.125 9.99905 8.125C8.27316 8.125 6.87405 6.72589 6.87405 5C6.87405 3.27411 8.27316 1.875 9.99905 1.875C11.7249 1.875 13.1241 3.27411 13.1241 5Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.75 16.7652C3.80859 13.3641 6.58395 10.625 9.99905 10.625C13.4142 10.625 16.1897 13.3642 16.2481 16.7654C14.3458 17.6383 12.2294 18.125 9.99932 18.125C7.76905 18.125 5.65247 17.6383 3.75 16.7652Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span transloco="My Profile">My Profile</span></a
      >
    </li>
    <li *isSuperAdmin>
      <a
        routerLink="/user-management"
        [queryParams]="{ management_type: 'customers' }"
        class="mb-6 flex items-center gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          width="20px"
          height="20px"
        >
          <path
            fill="#ffffff"
            stroke-width="0.1"
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
          />
        </svg>

        <span transloco="Vertragspartner">Vertragspartner</span></a
      >
    </li>
    <li>
      <a
        routerLink="/user-management"
        [queryParams]="{ management_type: 'users' }"
        class="mb-6 flex items-center gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 15.9396C13.1941 16.1417 13.9281 16.25 14.6875 16.25C15.9198 16.25 17.0854 15.9647 18.122 15.4565C18.124 15.4088 18.125 15.3607 18.125 15.3125C18.125 13.414 16.5859 11.875 14.6875 11.875C13.5058 11.875 12.4633 12.4713 11.8446 13.3793M12.5 15.9396V15.9375C12.5 15.0101 12.2624 14.1382 11.8446 13.3793M12.5 15.9396C12.5 15.9693 12.4997 15.999 12.4992 16.0286C10.9478 16.9627 9.1304 17.5 7.1875 17.5C5.2446 17.5 3.4272 16.9627 1.87577 16.0286C1.87526 15.9983 1.875 15.9679 1.875 15.9375C1.875 13.0035 4.25349 10.625 7.1875 10.625C9.1941 10.625 10.9409 11.7375 11.8446 13.3793M10 5.3125C10 6.8658 8.7408 8.125 7.1875 8.125C5.6342 8.125 4.375 6.8658 4.375 5.3125C4.375 3.7592 5.6342 2.5 7.1875 2.5C8.7408 2.5 10 3.7592 10 5.3125ZM16.875 7.1875C16.875 8.39562 15.8956 9.375 14.6875 9.375C13.4794 9.375 12.5 8.39562 12.5 7.1875C12.5 5.97938 13.4794 5 14.6875 5C15.8956 5 16.875 5.97938 16.875 7.1875Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span transloco="Teams">Teams</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/clients"
        class="mb-6 flex items-center gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00024 4.75C6.00024 4.33579 6.33603 4 6.75024 4H17.2502C17.6645 4 18.0002 4.33579 18.0002 4.75C18.0002 5.16421 17.6645 5.5 17.2502 5.5H6.75024C6.33603 5.5 6.00024 5.16421 6.00024 4.75ZM6.00024 10C6.00024 9.58579 6.33603 9.25 6.75024 9.25H17.2502C17.6645 9.25 18.0002 9.58579 18.0002 10C18.0002 10.4142 17.6645 10.75 17.2502 10.75H6.75024C6.33603 10.75 6.00024 10.4142 6.00024 10ZM6.00024 15.25C6.00024 14.8358 6.33603 14.5 6.75024 14.5H17.2502C17.6645 14.5 18.0002 14.8358 18.0002 15.25C18.0002 15.6642 17.6645 16 17.2502 16H6.75024C6.33603 16 6.00024 15.6642 6.00024 15.25Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.99023 4.75C1.99023 4.19772 2.43795 3.75 2.99023 3.75H3.00023C3.55252 3.75 4.00023 4.19772 4.00023 4.75V4.76C4.00023 5.31228 3.55252 5.76 3.00023 5.76H2.99023C2.43795 5.76 1.99023 5.31228 1.99023 4.76V4.75Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.99023 15.25C1.99023 14.6977 2.43795 14.25 2.99023 14.25H3.00023C3.55252 14.25 4.00023 14.6977 4.00023 15.25V15.26C4.00023 15.8123 3.55252 16.26 3.00023 16.26H2.99023C2.43795 16.26 1.99023 15.8123 1.99023 15.26V15.25Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.99023 10C1.99023 9.44772 2.43795 9 2.99023 9H3.00023C3.55252 9 4.00023 9.44772 4.00023 10V10.01C4.00023 10.5623 3.55252 11.01 3.00023 11.01H2.99023C2.43795 11.01 1.99023 10.5623 1.99023 10.01V10Z"
            fill="white"
          />
        </svg>

        <span transloco="Clients">Clients</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/settings"
        class="mb-6 flex items-center gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 6.6665L12.5 6.6665M12.5 6.6665C12.5 8.04722 13.6193 9.1665 15 9.1665C16.3807 9.1665 17.5 8.04722 17.5 6.6665C17.5 5.28579 16.3807 4.1665 15 4.1665C13.6193 4.1665 12.5 5.28579 12.5 6.6665ZM7.5 13.3332L17.5 13.3332M7.5 13.3332C7.5 14.7139 6.38071 15.8332 5 15.8332C3.61929 15.8332 2.5 14.7139 2.5 13.3332C2.5 11.9525 3.61929 10.8332 5 10.8332C6.38071 10.8332 7.5 11.9525 7.5 13.3332Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span transloco="Settings">Settings</span>
      </a>
    </li>
  </ul>
  <div
    class="border-b border-blue2 py-6 text-base font-semibold flex gap-3 px-4"
  >
    <div class="text-base font-medium">
      <span transloco="Language">Language</span>
      <span> :</span>
    </div>
    <button
      (click)="setLanguage('de')"
      transloco="German"
      class="border-2 border-light-blue1 rounded-3xl text-sm font-bold px-4 py-1 h-fit"
      [ngClass]="{
        'text-light-blue1 border-light-blue1': activeLang === 'de',
        'opacity-50': activeLang !== 'de'
      }"
    >
      German
    </button>
    <button
      (click)="setLanguage('en')"
      transloco="English"
      class="border-2 rounded-3xl text-sm font-bold px-4 py-1 h-fit"
      [ngClass]="{
        'text-light-blue1 border-light-blue1': activeLang === 'en',
        'opacity-50': activeLang !== 'en'
      }"
    >
      English
    </button>
  </div>
  <div class="px-4 pt-6 text-base font-semibold">
    <button
      class="mb-6 flex items-center gap-2 hover:opacity-80"
      (click)="logout()"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span transloco="Logout">Logout</span>
    </button>
  </div>
  <div
    class="mt-10 border-light-blue5 border-dashed border rounded-xl p-4"
    *ngIf="authService.license.value"
  >
    <div class="flex justify-between border-b border-gray14 pb-4">
      <div class="flex font-medium text-lg items-center gap-4 flex-wrap">
        <div transloco="Your Package" class="text-lg font-medium">
          Your Package
        </div>
        <div
          class="bg-light-blue5 text-primary rounded-lg font-bold text-[11px] flex justify-center items-center py-[2px] px-[6px] m-1 uppercase"
        >
          {{ authService.license?.value?.package?.name }}
        </div>
      </div>
      <a routerLink="/packages">
        <button
          class="tw-btn-secondary h-fit text-11px 2xl:py-2 px-[14px]"
          transloco="Upgrade"
        >
          Upgrade
        </button>
      </a>
    </div>
    <div class="grid grid-cols-2 my-4 mx-2">
      <div class="flex flex-col gap-4">
        <div class="text-base text-gray8">
          <div class="mb-1 text-light-blue6 opacity-60" transloco="Users">
            Users
          </div>
          <div class="font-bold text-base">
            {{ authService.license.value.usage.userCount }} /
            <span class="text-orange1">{{
              authService.license.value.usage.availableUserCount
            }}</span>
          </div>
        </div>
        <div class="text-base text-gray8">
          <div class="mb-1 text-light-blue6 opacity-60" transloco="Anfragen">
            Anfragen
          </div>
          <div class="font-bold text-base">
            {{ authService.license.value.usage.projectCount }} /
            <span class="text-orange1">{{
              authService.license.value.usage.availableProjectCount
            }}</span>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div transloco="Speicherplatz" class="text-light-blue6 opacity-60">
          Speicherplatz
        </div>
        <div class="w-fit h-fit relative">
          <svg
            height="120"
            width="120"
            viewBox="0 0 100 100"
            class="rounded-full loader-shadow -rotate-90"
          >
            <circle
              class="circle"
              cx="50"
              cy="50"
              r="46"
              stroke="#ffffff"
              stroke-width="8"
              fill-opacity="0"
            />
            <circle
              class="circle"
              cx="50"
              cy="50"
              r="46"
              stroke="#FF8811"
              stroke-width="8"
              fill-opacity="0"
              stroke-dasharray="1000"
              [attr.stroke-dashoffset]="storageDashOffset"
              stroke-linecap="round"
            />
          </svg>
          <div
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center"
          >
            <div class="font-bold text-11px text-gray8 whitespace-nowrap">
              {{ authService.license.value.usage.storageUsage | fileSize: 1 }}
              <br />
              <span class="text-orange1"
                >/
                {{
                  authService.license.value.usage.availableStorage | fileSize
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
