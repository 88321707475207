import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { TmpFilesService } from '../../backend-services/tmp-files.service';
import { RequestViewPageService } from '../request-view-page.service';

@Injectable({
  providedIn: 'root',
})
export class UploadProofService {
  modalEvents: Subject<
    | {
        type: 'open';
      }
    | {
        type: 'upload-file';
        file: File;
      }
    | { type: 'init-close' }
    | { type: 'close' }
  > = new Subject();

  uploadProgress = 0;
  currentFileName?: string;
  uploadStatus: 'idle' | 'uploading' | 'uploaded' | 'error' = 'idle';
  isSubmitting: boolean = false;

  uploadResponse?: {
    url: string;
    path: string;
    preview_url: string;
    thumbnail_url: string;
    file_size: number;
    file_name: string;
  };

  constructor(
    private tmpFilesService: TmpFilesService,
    private requestViewPageService: RequestViewPageService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'upload-file':
          this.uploadProgress = 0;
          this.uploadStatus = 'uploading';
          this.currentFileName = event.file.name;
          this.modalEvents.next({ type: 'open' });

          this.tmpFilesService.tmpFileUpload(event.file).subscribe((data) => {
            if (data.type === HttpEventType.UploadProgress) {
              const total = data.total || 1;
              this.uploadProgress = Math.min(
                99,
                Math.floor((100 * data.loaded) / total)
              );
            }

            if (data.type === HttpEventType.Response) {
              this.uploadProgress = 100;
              if (data.status === 200) {
                this.uploadStatus = 'uploaded';
                this.uploadResponse = (data.body as any).data;
              } else {
                this.uploadStatus = 'error';
              }
            }
          });
          break;

        case 'init-close':
          this.modalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.reset();
          break;
      }
    });
  }

  reset() {
    this.uploadProgress = 0;
    this.uploadStatus = 'idle';
    this.uploadResponse = undefined;
  }

  // add the uploaded data to current section
  handleSubmit() {
    const newStatus = this.requestViewPageService.producerHash ? 303 : 203;
    const groupIndex =
      this.requestViewPageService.selectedSectionMetaData!.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData!.sectionIndex;

    this.requestViewPageService.requestData!.section_groups![
      groupIndex
    ].sections[sectionIndex]!.proof_file = {
      is_new: true,
      path: this.uploadResponse!.path,
      preview_url: this.uploadResponse!.preview_url,
      thumbnail_url: this.uploadResponse!.thumbnail_url,
      url: this.uploadResponse!.url,
      file_size: this.uploadResponse!.file_size,
      file_name: this.uploadResponse!.file_name,
      title: this.uploadResponse!.file_name,
    };
    this.requestViewPageService.requestData!.section_groups![
      groupIndex
    ].sections[sectionIndex]!.status = newStatus;

    this.requestViewPageService.handleRequestDataChange();
    this.modalEvents.next({ type: 'close' });
  }
}
