import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { CustomerService } from '../../backend-services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import { EditUserModalService } from '../edit-user-modal/edit-user-modal.service';
import { data } from 'autoprefixer';
import { UserService } from '../../backend-services/user.service';

@Component({
  selector: 'app-customer',
  templateUrl: './manage-single-customer.component.html',
  styleUrls: ['./manage-single-customer.component.scss'],
})
export class ManageSingleCustomerComponent {
  @ViewChild('confirmBackModalTrigger') confirmBackModalTrigger:
    | ElementRef
    | undefined;

  customerForm = new FormGroup({
    label: new FormControl('', []),
    company: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    vat_nr: new FormControl(''),
    first_name: new FormControl('', []),
    last_name: new FormControl('', []),
    email: new FormControl('', [Validators.email]),
    billing_street: new FormControl('', []),
    billing_house_number: new FormControl('', []),
    billing_zip: new FormControl('', []),
    billing_city: new FormControl('', []),
    billing_country: new FormControl('', []),
    billing_email: new FormControl('', [Validators.email]),
    invoice_by_mail: new FormControl(true, []),
  });

  licenseForm = new FormGroup({
    name: new FormControl('Free'),
    startDate: new FormControl(dayjs().format('DD.MM.YYYY')),
    endDate: new FormControl(' - '),
    availableProjectCount: new FormControl(3),
    availableUserCount: new FormControl(1),
    availableStorage: new FormControl(1073741824),
    projectCount: new FormControl(0),
    userCount: new FormControl(0),
    storageUsage: new FormControl(0),
  });

  userForm = new FormGroup({
    first_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    enabled: new FormControl(true),
    set_password: new FormControl(true),
    password: new FormControl(),
    role_id: new FormControl(2, [Validators.required]),
    customer_id: new FormControl('', []),
  });

  customerFormLoading = false;

  id: number | null = null;

  initialData: any = undefined;

  selectedLogo: string | ArrayBuffer | null = null;
  selectedLogoFile: Blob | null = null;

  constructor(
    private _location: Location,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private editUserModalService: EditUserModalService,
    private userService: UserService
  ) {
    this.initialData = this.customerForm.value;

    this.route.paramMap.subscribe({
      next: (val) => {
        const id = val.get('id');
        if (id) {
          this.id = parseInt(id);
          this.customerFormLoading = true;
          this.customerForm.disable();

          this.customerService.getCustomerById(this.id).subscribe({
            next: (res) => {
              if (res) {
                this.customerFormLoading = false;

                // set form values
                this.labelControl.setValue(res.label);
                this.companyControl.setValue(res.company);
                this.vatControl.setValue(res.vat_nr);
                this.firstNameControl.setValue(res.first_name);
                this.lastNameControl.setValue(res.last_name);
                this.emailControl.setValue(res.email);
                this.billingStreetControl.setValue(res.billing_street);
                this.billingHouseNumberControl.setValue(
                  res.billing_house_number
                );
                this.billingZipControl.setValue(res.billing_zip);
                this.billingCityControl.setValue(res.billing_city);
                this.billingEmailControl.setValue(res.billing_email);

                if (res.logo) {
                  this.selectedLogo = res.logo.url;
                }

                this.customerForm
                  .get('invoice_by_mail')!
                  .setValue(res.invoice_by_mail);

                this.customerForm.enable();

                this.licenseNameControl.setValue(res.license?.package.name, {
                  emitEvent: true,
                });
                this.licenseStartDateControl.setValue(
                  res.license?.validity.start_date
                    ? dayjs(res.license.validity.start_date).format(
                        'DD.MM.YYYY'
                      )
                    : ' - ',
                  { emitEvent: true }
                );
                this.licenseEndDateControl.setValue(
                  res.license?.validity.end_date
                    ? dayjs(res.license.validity.end_date).format('DD.MM.YYYY')
                    : ' - ',
                  { emitEvent: true }
                );
                this.availableUserCountControl.setValue(
                  res.license?.usage.availableUserCount,
                  { emitEvent: true }
                );
                this.availableStorageControl.setValue(
                  res.license?.usage.availableStorage,
                  { emitEvent: true }
                );
                this.availableProjectCountControl.setValue(
                  res.license?.usage.availableProjectCount,
                  { emitEvent: true }
                );
                this.userCountControl.setValue(res.license?.usage.userCount, {
                  emitEvent: true,
                });
                this.projectCountControl.setValue(
                  res.license?.usage.projectCount,
                  { emitEvent: true }
                );
                this.storageUsageControl.setValue(
                  res.license?.usage.storageUsage,
                  { emitEvent: true }
                );

                this.initialData = this.customerForm.value;
              }
            },
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                this.translocoService.translate(`User Not Found`)
              );
            },
          });
        }
      },
    });

    this.editUserModalService.modalEvents.subscribe((ev) => {
      if (ev.type === 'user-data-updated') {
        this.userForm.get('first_name')?.setValue(ev.user.first_name);
        this.userForm.get('last_name')?.setValue(ev.user.last_name);
        this.userForm.get('email')?.setValue(ev.user.email);
        this.userForm.get('enabled')?.setValue(ev.user.enabled);
        this.userForm.get('set_password')?.setValue(ev.user.set_password);
        this.userForm.get('password')?.setValue(ev.user.password);
        this.userForm.get('role_id')?.setValue(ev.user.role_id);
        this.userForm.get('customer_id')?.setValue(ev.user.customer_id);

        // update existing contact person data
        this.firstNameControl.setValue(ev.user.first_name);
        this.lastNameControl.setValue(ev.user.last_name);
        this.emailControl.setValue(ev.user.email);
      }
    });
  }

  get labelControl() {
    return this.customerForm.get('label') as AbstractControl;
  }

  get companyControl() {
    return this.customerForm.get('company') as AbstractControl;
  }

  get vatControl() {
    return this.customerForm.get('vat_nr') as AbstractControl;
  }

  get firstNameControl() {
    return this.customerForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.customerForm.get('last_name') as AbstractControl;
  }

  get emailControl() {
    return this.customerForm.get('email') as AbstractControl;
  }

  get billingStreetControl() {
    return this.customerForm.get('billing_street') as AbstractControl;
  }

  get billingHouseNumberControl() {
    return this.customerForm.get('billing_house_number') as AbstractControl;
  }

  get billingZipControl() {
    return this.customerForm.get('billing_zip') as AbstractControl;
  }

  get billingCityControl() {
    return this.customerForm.get('billing_city') as AbstractControl;
  }

  get billingCountryControl() {
    return this.customerForm.get('billing_country') as AbstractControl;
  }

  get billingEmailControl() {
    return this.customerForm.get('billing_email') as AbstractControl;
  }
  get invoiceByMailControl() {
    return this.customerForm.get('invoice_by_mail') as FormControl;
  }

  get licenseNameControl() {
    return this.licenseForm.get('name') as FormControl;
  }

  get licenseStartDateControl() {
    return this.licenseForm.get('startDate') as FormControl;
  }

  get licenseEndDateControl() {
    return this.licenseForm.get('endDate') as FormControl;
  }

  get availableProjectCountControl() {
    return this.licenseForm.get('availableProjectCount') as FormControl;
  }

  get availableUserCountControl() {
    return this.licenseForm.get('availableUserCount') as FormControl;
  }

  get availableStorageControl() {
    return this.licenseForm.get('availableStorage') as FormControl;
  }

  get projectCountControl() {
    return this.licenseForm.get('projectCount') as FormControl;
  }

  get userCountControl() {
    return this.licenseForm.get('userCount') as FormControl;
  }

  get storageUsageControl() {
    return this.licenseForm.get('storageUsage') as FormControl;
  }

  handleSubmit() {
    this.customerForm.markAllAsTouched();
    this.userForm.markAllAsTouched();

    if (this.customerForm.valid && this.userForm.valid) {
      this.customerFormLoading = true;

      if (!this.id) {
        const formData = new FormData();
        Object.entries(this.customerForm.value).forEach(
          ([key, value]: [string, any]) => {
            if (value !== null && value !== undefined && value !== '') {
              formData.append(key, value);
            }
          }
        );

        if (this.selectedLogoFile) {
          formData.append('logoFile', this.selectedLogoFile);
        }

        this.customerService.createCustomer(formData).subscribe({
          next: (res) => {
            this.customerFormLoading = false;
            if (res.success) {
              const userData = {
                ...this.userForm.value,
                customer_id: res.data.id,
              };
              this.toastr.success(
                this.translocoService.translate(
                  `Neuer Vertragspartner erfolgreich erstellt!`
                )
              );

              this.editUserModalService.fetchCustomers();

              this.userService.createUser(userData).subscribe({
                next: (res) => {
                  if (res.success) {
                    this.toastr.success(
                      this.translocoService.translate(
                        `Neuer Benutzer wurde erfolgreich erstellt!`
                      )
                    );
                    this.router.navigate([`/user-management/`], {
                      replaceUrl: true,
                      queryParams: {
                        management_type: 'customers',
                      },
                    });
                  }
                },
                error: (res) => {
                  if (!!res.error?.message?.email) {
                    this.toastr.error(
                      this.translocoService.translate(
                        `Diese E-Mail existiert bereits`
                      )
                    );
                  } else {
                    console.log('err', res.error);
                    this.toastr.error(
                      res.error?.message
                        ? this.translocoService.translate(res.error?.message)
                        : res.error?.error
                        ? this.translocoService.translate(res.error.error)
                        : this.translocoService.translate(
                            `Something went wrong`
                          )
                    );
                  }
                  this.customerFormLoading = false;
                },
              });
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.message
                ? this.translocoService.translate(res.error?.message)
                : res.error?.error
                ? this.translocoService.translate(res.error.error)
                : this.translocoService.translate(`Something went wrong`)
            );
            this.customerFormLoading = false;
          },
        });
      } else {
        const formData = new FormData();
        Object.entries(this.customerForm.value).forEach(
          ([key, value]: [string, any]) => {
            if (value !== null && value !== undefined && value !== '') {
              formData.append(key, value);
            }
          }
        );

        if (this.selectedLogoFile) {
          formData.append('logoFile', this.selectedLogoFile);
        }

        this.customerService.updateCustomer(this.id, formData).subscribe({
          next: (res) => {
            this.customerFormLoading = false;
            if (res.success) {
              this.customerForm.markAsUntouched();
              this.toastr.success(
                this.translocoService.translate(`Ihr Profil wurde gespeichert`)
              );

              this.router.navigate([`/user-management/`], {
                replaceUrl: true,
                queryParams: {
                  management_type: 'customers',
                },
              });
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.message
                ? this.translocoService.translate(res.error?.message)
                : res.error?.error
                ? this.translocoService.translate(res.error.error)
                : this.translocoService.translate(`Something went wrong`)
            );
            this.customerFormLoading = false;
          },
        });
      }
    }
  }

  get storageDashOffset() {
    const storageUsage = this.storageUsageControl.value;
    const availableStorage = this.availableStorageControl.value;

    const percentage = Math.ceil((storageUsage / availableStorage) * 100);

    return 1000 - percentage * 2.9;
  }

  goBackCheck() {
    if (
      JSON.stringify(this.initialData) !==
      JSON.stringify(this.customerForm.value)
    ) {
      this.confirmBackModalTrigger?.nativeElement?.click();
    } else {
      this.goBack();
    }
  }

  goBack() {
    this._location.back();
  }

  onFileChange(event: any) {
    this.selectedLogoFile = event.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      this.selectedLogo = reader.result;
    };

    reader.readAsDataURL(this.selectedLogoFile!);
  }

  removeSelectedLogo() {
    this.selectedLogo = null;
  }

  openEditUserModal() {
    this.editUserModalService.modalEvents.next({
      type: 'new-with-customer',
      user: this.userForm.value,
    });
  }
}
