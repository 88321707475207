<app-accordian-item
  title="Versionsverlauf"
  iconSize="sm"
  [variant]="2"
  *ngIf="requestViewPageService.selectedSection"
  class="font-Montserrat"
>
  <div heading class="ml-2 bg-gray8 rounded-lg px-2 py-1 text-xs">
    {{ requestViewPageService.selectedSection.file_history.length }}
  </div>
  <div
    *ngIf="requestViewPageService.selectedSection.file_history.length === 0"
    class="font-semibold text-13px text-primary opacity-50 leading-20.8px"
    transloco="Es gibt keine versionsverlauf."
  >
    Es gibt keine versionsverlauf.
  </div>

  <div class="flex flex-col gap-4 pt-4" *ngIf="requestViewPageService.selectedSection.file_history.length">
    <div
      *ngFor="
      let historyItem of requestViewPageService.selectedSection.file_history
    "
      class="border-l border-gray9 pl-3 relative ml-2 flex gap-1"
    >
      <div class="h-2 w-2 rounded-full bg-gray7 absolute left-0 top-0 -translate-x-1/2"></div>
      <div class="flex-grow grid grid-cols-5 text-primary opacity-80 text-xs font-semibold gap-1">
        <div class="col-span-3">{{ formatedFileName(historyItem.title) }}</div>
        <div class="col-span-2">
          {{historyItem.file_type.name}} | {{historyItem.file_size  | fileSize}}
        </div>
        <div class="col-span-5 font-normal opacity-70 flex gap-2">
          <div *ngIf="historyItem.user_name">{{historyItem.user_name}} . </div>
          <div>{{historyItem.timestamp | date: 'dd. MMM, HH:mm'}}</div>
        </div>
        <div class="col-span-5 font-normal opacity-100 flex gap-2" *ngIf="shouldShowStatus(historyItem.current_status_id)">
          <app-status-display [variant]="2" [statusId]="historyItem.current_status_id" class="-ml-2"></app-status-display>
        </div>
      </div>
      <div class="flex items-center">
        <a class="rounded-xl border p-2 border-gray12" [href]="sanitizer.bypassSecurityTrustUrl(historyItem.url)" target="_blank">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 10C20 10 16.25 3.125 10 3.125C3.75 3.125 0 10 0 10C0 10 3.75 16.875 10 16.875C16.25 16.875 20 10 20 10ZM1.46625 10C2.07064 9.0814 2.7658 8.22586 3.54125 7.44625C5.15 5.835 7.35 4.375 10 4.375C12.65 4.375 14.8487 5.835 16.46 7.44625C17.2354 8.22586 17.9306 9.0814 18.535 10C18.4625 10.1087 18.3825 10.2288 18.2913 10.36C17.8725 10.96 17.2537 11.76 16.46 12.5538C14.8487 14.165 12.6487 15.625 10 15.625C7.35 15.625 5.15125 14.165 3.54 12.5538C2.76456 11.7741 2.07065 10.9186 1.46625 10Z" fill="#97B7C4"/>
            <path d="M10 6.875C9.1712 6.875 8.37634 7.20424 7.79029 7.79029C7.20424 8.37634 6.875 9.1712 6.875 10C6.875 10.8288 7.20424 11.6237 7.79029 12.2097C8.37634 12.7958 9.1712 13.125 10 13.125C10.8288 13.125 11.6237 12.7958 12.2097 12.2097C12.7958 11.6237 13.125 10.8288 13.125 10C13.125 9.1712 12.7958 8.37634 12.2097 7.79029C11.6237 7.20424 10.8288 6.875 10 6.875ZM5.625 10C5.625 8.83968 6.08594 7.72688 6.90641 6.90641C7.72688 6.08594 8.83968 5.625 10 5.625C11.1603 5.625 12.2731 6.08594 13.0936 6.90641C13.9141 7.72688 14.375 8.83968 14.375 10C14.375 11.1603 13.9141 12.2731 13.0936 13.0936C12.2731 13.9141 11.1603 14.375 10 14.375C8.83968 14.375 7.72688 13.9141 6.90641 13.0936C6.08594 12.2731 5.625 11.1603 5.625 10Z" fill="#97B7C4"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</app-accordian-item>
